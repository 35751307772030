export const Experience = {
    id: 'experience',
    lightBg: false,
    lightText: true,
    lightTextDesc: false,
    darkText: false,
    topLine: 'University of Guelph | Aug 2020 - Jan 2021',
    headline: 'Experience',
    description1: 'Website Support Developer ',
    topLine2: 'Atlas Van Lines | May 2019 - Aug 2019',
    description2: 'Operations Team Member'

}
