export const projectOne = {
    id: 'projects',
    lightBg: true,
    theSection: false,
    lightText: false,
    lightTextDesc: false,
    darkText: true,
    topLine: '',
    headline: 'Projects',
    description1: 'Friend\'s Website',
    description2: 'Tic-Tac-Toe',
    description3: 'Check Out Everything'

}
